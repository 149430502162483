// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import themeConfig from './themeConfig'
import dataTables from '../../views/Portal/DataTables/store/reducer'
import installSapCatProds from "../../views/Portal/DataTables/store/reducer/installSap";

const appReducer = combineReducers({
  auth,
  navbar,
  layout,
  themeConfig,
  dataTables,
  installSapCatProds
})

const rootReducer = (state, action) => {
  return appReducer(state, action)
}

export default rootReducer
