// ** React Imports
import {useState, createContext} from 'react'
import {createAbility} from "../../configs/acl/ability";

// ** Create Context
const Abilities = createContext()

const CreateAbilities = ({children}) => {
    const [ability, setAbilities] = useState(createAbility())

    return <Abilities.Provider value={{ability, setAbilities}}>{children}</Abilities.Provider>;
}

export {Abilities, CreateAbilities}


/*
  useEffect(() => {
    async function getUserAbilities() {
      console.log("comenzando fetch de abilidades");
      const headers = {
        'Authorization': completeEnvUrl('REACT_APP_AUTH_NODE'),
      };
      const initialAbility = [
        {
          action: 'manage',
          subject: 'Auth'
        }
      ]
      console.log(getUserRoleId());
      const abilities = await axios.get(
          process.env.REACT_APP_NODE_URL + '/react/getAdminRoleFeatures/' + (getUserRoleId()),
          {headers: headers});
      console.log("armando habilidades");
      if (abilities.data.id) {
        const abilityObj = abilities.data;
        Object.fromEntries(Object.entries(abilityObj).filter(([key, val]) => val === 1));
        for (const key in abilityObj) {
          initialAbility.push({
            action: 'manage',
            subject: key
          })
        }
      }
      const abilityObj = createAbility(initialAbility)
      console.log("setteando habilidades");
      setAbilities(abilityObj)
      setNewAbilities(abilityObj)
      let data = getUserData();
      data.ability= initialAbility;
      localStorage.setItem('userData', JSON.stringify(data))
      ability = abilityObj;
    }
    console.log(newAbilities.g.length);
    if(isUserLoggedIn() && !newAbilities.g.length){
      getUserAbilities()
    }
  }, []);
*/
