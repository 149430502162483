import {useEffect, createContext, useState} from 'react';
import {useSelector} from 'react-redux';

import {Helmet} from "react-helmet";

const ThemeConfig = createContext();

const ThemeConfigContext = ({children}) => {
    const configs = useSelector(state => state.themeConfig, '');

    const [themeConfig, setThemeConfig] = useState(configs)
    useEffect(() => {
        setThemeConfig(configs)
    }, []);

    return (
        <ThemeConfig.Provider value={{themeConfig}}>
            <Helmet>
                <title>{themeConfig.app.appPageTitle}</title>
                <link rel="icon" href={themeConfig.app.appFaviconImage} type="image/x-icon"/>
                <link rel="shortcut icon" href={themeConfig.app.appFaviconImage} type="image/x-icon"/>
            </Helmet>
            {children}
        </ThemeConfig.Provider>
    );
};

export {ThemeConfig, ThemeConfigContext};
