// ** Initial State
const initialState = {
    app: {
        appName: 'Portal B2b',
        appLogoImage: require('@src/assets/images/logo/logovicsa.png').default,
        appFaviconImage:'https://vicsaconectados.vteximg.com.br/arquivos/favicon.ico',
        appMagentoUrlLogin:'https://1olboqat7bk27u3e.mojostratus.io/customer/account/login/',
        appPageTitle:'Portal B2b'
    },
    layout: {
        isRTL: false,
        skin: 'light', // light, dark, bordered, semi-dark
        routerTransition: 'fadeIn', // fadeIn, fadeInLeft, zoomIn, none or check this for more transition https://animate.style/
        type: 'vertical', // vertical, horizontal
        contentWidth: 'full', // full, boxed
        menu: {
            isHidden: false,
            isCollapsed: false
        },
        navbar: {
            // ? For horizontal menu, navbar type will work for navMenu type
            type: 'floating', // static , sticky , floating, hidden
            backgroundColor: 'white' // BS color options [primary, success, etc]
        },
        footer: {
            type: 'static' // static, sticky, hidden
        },
        customizer: false,
        scrollTop: true // Enable scroll to top button
    }
}

const themeConfigReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_THEME_CONFIG':
            return {
                ...state,
                app: action.data.app,
            }
        default:
            return state
    }
}

export default themeConfigReducer
