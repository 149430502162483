// ** Initial State
const initialState = {
    data: [],
    total: 1,
    params: {},
    allData: [],
    errorMsg:'',
    isLoading:true,
    code:'',
    hello:'world'
}

const installSapCatProdsReducer = (state = initialState, action) => {

    switch (action.type) {
        case 'GET_DATA_SAP':
            return {
                ...state,
                allData: action.allData,
                data: action.data,
                total: action.totalPages,
                params: action.params,
                isLoading:action.isLoading,
            }
        case 'ERROR_SAP':
            return{
                ...state,
                errorMsg:action.errorMsg ?? 'Ocurrio un problema de lado del servidor...',
                isLoading:action.isLoading,
            }
        case 'RESET_DATA_SAP':
            return{
                ...initialState
            }
        default:return state
    }
}


export default installSapCatProdsReducer