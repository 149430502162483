// ** Initial user ability
/**
 * action:'manage' es = a que puede hacer de todo refiriendose a CRUD.
 * subject:'all' es = a que puede acceder a cualquier página. Admin.
 */
export const initialAbility = [
  {
    action: 'read',
    subject: 'error'
  }
]

export const _ = undefined
