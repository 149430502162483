import {Ability} from '@casl/ability'
import {initialAbility} from './initialAbility'


//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this
const userData = JSON.parse(localStorage.getItem('userData'))
let existingAbility = userData ? userData.ability : null


export const createAbility = (ability = null) => {
    if (ability) {
        return new Ability(ability)
    }
    if (existingAbility) {
        return new Ability(existingAbility)
    }
    return new Ability(initialAbility)
}

export default new Ability(existingAbility || initialAbility)
