// ** Initial State
const initialState = {
  data: [],
  total: 1,
  params: {},
  allData: [],
  errorMsg:'',
  isLoading:true,
  code:''
}

const DataTablesReducer = (state = initialState, action) => {

  switch (action.type) {
    case 'FETCH_DATA_REQUEST':
      return {
        ...state,
        allData: action.allData,
        data: action.data,
        total: action.totalPages,
        params: action.params,
        isLoading:true,
        errorMsg: ''
      }
    case 'GET_DATA':
      return {
        ...state,
        allData: action.allData,
        data: action.data,
        total: action.totalPages,
        params: action.params,
        isLoading:action.isLoading,
      }
    case 'ERROR':
      return{
        ...state,
        errorMsg:action.errorMsg ?? 'Ocurrio un problema de lado del servidor...',
        isLoading:action.isLoading,
      }
    case 'RESET_DATA':
      return{
        ...initialState
      }
    default:return state
  }
}


export default DataTablesReducer


